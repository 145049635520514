

.file-dash {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0,0,0,0.7);
    z-index: 2;
    display: flex;

    .dash-panel {
        padding: 10px;
        margin: auto;
        background: white;
        width: 80%;
        max-width: 1500px;
        height: 50%;
        border-radius: 5px;

        .file-input {

        }

        .top-bar {
            padding: 5px;
            display: flex;
            justify-content: space-between;
            height: 40px;

            border-bottom: 1px solid #d7dfd4;
            .top-header {
                font-weight: bold;
                font-size: 1.5em;
            }
        }

        .file-area {
            height: 200px;
            background-color: #ededed;
            display: flex;
            margin: 10px;
            border-radius: 5px;

            .area-action {
                display: flex;
                margin: auto;

                #add-media  {
                    display: flex;
                    flex-direction: column;
                    
                    & > svg {
                        margin: auto;
                    }
                }
            }
        }

        .upload-list {
            overflow-y: auto;
            overflow-x: hidden;
            display: flex;
            flex-direction: column;
        }

        .file-upload {
            width: inherit;
            margin: 5px;
            height: 40px;
            border: 1px solid #d7dfd4;
            border-radius: 5px;
            display: flex;
            & > * {
                padding: 5px;
            }
            .upload-details {
                display: flex;
                flex-direction: row;
                flex-grow: 1;
                justify-content: space-between;
                overflow-y: hidden;

                & > * {
                    margin: auto 0;

                }
                .file-name {
                    white-space: nowrap;
                    margin: auto 0 ;

                }
            }
            .upload-percentage {
                display: flex;
                padding: 0px 10px;
                white-space: nowrap;

                & div {
                    margin: auto;
                }
            }


            &.finished {
                background-color: #c9e6c4;
            }
            &.canceled {
                background-color: #f3f687;
            }
            &.uploading {
                // background-color: green;
            }
            &.error {
                background-color: #cb7575;
                // background-color: green;
            }

            transition: background-color 0.2s linear;

            .controls {
                margin: auto;
                opacity: 0;
                pointer-events: none;
                &.uploading {
                    opacity: 1;
                    pointer-events: auto;
                }

                button {
                    margin: auto;
                }
            }
        }

        .page-viewer {
            height: calc(100% - 50px);
            overflow: hidden;

            --page-controls: 50px;

            .page-viewer-controls {
                display: flex;
                justify-content: center;
                height: var(--page-controls);
            }

            .page {
                height: calc( 100% - var(--page-controls));
                flex-direction: column;
                display: flex;
                flex-direction: column;
                overflow: hidden;

                .media-selection {
                    overflow-y: auto;
                    display: grid;
                    grid-template-columns: repeat(1, 100%);
                    grid-auto-rows: 1fr;
                    grid-gap: 5%;
                    height: 100%;
                    padding: 15px;
                    .media-item {
                        --row-height: 80px;

                        overflow: hidden;
                        box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.40);

                        border-radius: 5px;
                        height: var(--row-height);
                        a {
                            display: flex;
                            height: inherit;
                            .media-icon-container {
                                width: var(--row-height);
                                height: inherit;
                                img {
                                    height: inherit;
                                }
                            }
                            .media-details-container {
                                width: calc(100% - var(--row-height));
                                display: flex;
                                & > div {
                                    color: grey;
                                    width: 100%;
                                    margin: auto;
                                    padding-left: 10px;
                                    & > * {
                                        font-size: 75%;
                                        margin: auto;
                                    }
                                    div {
                                        font-weight: bold;
                                    }
                                }
                            }


                        }
                    }
                }
                .media-actions {
                    display: flex;
                    justify-content: space-between;
                    & > * {
                        box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.40);
                        border-radius: 5px;
                        padding: 10px;
                        margin: 5px;
                        color: white;
                    }
                    .delete {
                        background-color: red;
                    }
                    .submit {
                        background-color: green;
                    }

                }
            }
        }
    }
}
