config-page {
    display: flex;
    flex-direction: column;
    min-height: inherit;
    padding: 25px;
}

config-page > * {
    max-width: 1000px;
    width: 100%;
    margin: 0px auto;
    padding: 25px 0px;
}

config-page button {
    background: #46454e;
    color: white;
    padding: 5px;
    margin: 5px 0px;
    width: 100%;
    border-radius: 5px;
}

config-page h1 {
    padding: 10px;
}
config-page form {
    position: relative;
}
config-page button:hover {
    background: white;
    color:  #46454e;
}

config-page input {
    border: 1px solid #46454e !important;
    border-radius: 5px;
    height: 25px;
    padding: 5px;
    margin: 5px 0px;
    width: 100%;
}

config-page input-container {
    display: flex;
    width: 100%;
}

config-page button-container {
    display: flex;
}
config-page > button {
    width: 100%;
}

smtp-config {
    position: relative;
    display: block;
}


smtp-config h1 {
    margin: auto;
}

register-comp {
    position: relative;
    display: block;

}
