

portal-page {
    display: flex;
    min-height: inherit;
}

portal-page #portal-card {
    display: flex;
    flex-direction: column;
    margin: auto;
    max-width: 500px;
    width: 100%;
}

portal-page #login-container {
}

portal-page input {
    background-color: white;
    border-radius: 5px;
    height: 25px;
    border: 0px;
    margin: 5px;
    /* padding: 5px 0 */
    /* max-width: 500px; */
    width: 100%;
    display: flex;
}

portal-page form div  {
    border: 1px solid black;
    border-radius: 5px;
    margin: 5px 0px;
    width: 100%;
    display: flex;
}


portal-page button {
    /* padding: 5px; */
    /* border: 1px solid black; */
    border-radius: 5px;
    margin: 5px 0px;
    height: 35px;
    /* max-width: 500px; */
    width: calc(100% + 2px);
    background-color: #46454e;
    color: white;

}

portal-page button:hover {
    background-color: white;
    color: #46454e;
    border: 1px solid #46454e;
}
