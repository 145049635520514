#app-footer {
    /* height: var(--footer-height); */
    width: var(-footer-width);
    background-color: #46454e;
    color: white;
    display: flex;
    flex-direction: column;
}

#app-footer > * {
    flex-grow: 1;
    /* min-height: 20px; */
}

#top-footer {
    display: flex;
    flex-direction: row;
    padding: 25px 0px;
}

#top-footer > * {
    /* flex-grow: 1; */
    width: calc( 100% / 3);
}

#footer-name {
    font-weight: bold;
    font-family: 'Big Shoulders Stencil Text', cursive;
    text-align: center;
    vertical-align: middle;
}

@media (max-width:1024px){
    #top-footer {
        flex-direction: column;
    }

    #top-footer > * {
        width: 100%;
    }
}


#top-left-footer {

}

#top-mid-footer {

}
#top-right-footer {

}


#mid-footer {
    display: flex;
    flex-direction: row;
    padding-bottom: 25px;
}

#mid-footer > * {
    /* flex-grow: 1; */
    width: calc( 100% / 3);
}


@media (max-width:1024px){
    #mid-footer {
        flex-direction: column-reverse;
    }

    #mid-footer > * {
        width: 100%;
        padding: 10px;
    }
}


#mid-left-footer {
    display: flex;
}

#footer-additional-links {
    font-size: 1.2em;
    display: flex;
    flex-direction: column;
    margin: auto;
    font-weight: bold;
    
}

#mid-mid-footer {

}

#mid-right-footer {

}

#footer-contact-title {
    font-family: 'Big Shoulders Stencil Text', cursive;
}

#footer-contact-items {
    display: flex;
    flex-direction: column;
    font-size: 1.2em;
}

#footer-contact-items > * {
    margin: auto;
}

#bottom-footer {
    display: flex;
    flex-direction: row;
    background-color: black;
}

#bottom-footer > * {
    /* flex-grow: 1; */
    width: calc( 100% / 3);
}

@media (max-width:1024px){
    #bottom-footer {
        flex-direction: column;
        padding: 5px;
    }

    #bottom-footer > * {
        width: 100%;
    }
}


#bottom-left-footer {

}

#bottom-mid-footer {
}

#bottom-right-footer {

}

#footer-avalon-plug {
    font-size: .8em;
    padding: 5px;
    display: flex;
    font-weight: bold;
}

#footer-avalon-plug a {
    margin: auto;
    display: flex;
    text-align: center;
}
#footer-avalon-plug a > *{
    margin: auto;
}

#footer-logo {
    height: 20vw;
    max-height: 200px;
    min-height: 150px;
    display: flex;
}

#footer-logo img {
    height: 100%;
    margin: auto;
}

.footer-link {
    display: flex;
}

.footer-link > * {
    text-align: center;
    vertical-align: middle;
    padding: 5px;
    margin: auto 5px;
    font-weight: bold;
}
