#contact-page {
    min-height: calc(100vh - var(--footer-height) + var(--nav-height));
    display: flex;
    flex-direction: row;
    min-height: inherit;
}

#contact-page form button {
    border: 1px solid;
}



#communication-lines {
    width: 50%;
    overflow: hidden;
    min-height: inherit;
    position: relative;
}

#communication-lines > * {
    top: 0;
    position: absolute;
}

#communication-lines #filler {
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.6);
    color: white;
    display: flex;
    flex-direction: column;
}

#filler #filler-container {

    margin: auto;
    display: flex;
    flex-direction: column;
    padding: 20px;
}

#communication-lines #filler h3 {
    font-size: 5em;
    color: rgba(255,255,255,0.75);
}

#communication-lines #scalable-image {
    max-height: calc(100%);
}

#communication-form {
    width: 50%;
    display: flex;
}

.card {
    padding-top: 50px;
    display: flex;
    font-size: 0.95em;
    font-weight: bold;
}


#phone-card {
    display: flex;
    flex-direction: column;
    width: 200px;
    height: 200px;
    color: #46454e;
    padding: 5px;
}
#phone-card * {
    margin: auto;
}

#email-card {
    display: flex;
    flex-direction: column;
    width: 200px;
    height: 200px;
    color: #46454e;
    padding: 5px;
}
#email-card * {
    margin: auto;
}
.card > * {
    margin: auto;
    background: rgba(255,255,255,0.75);
    border-radius: 5px;
}

@media (max-width:1024px){
    #contact-page {
        flex-direction: column;
    }
    #communication-lines{
        width: 100%;
    }
    #communication-form {
        width: 100%;
    }
    #communication-lines #filler h3 {
        font-size: 4em;
    }
    #communication-lines #filler #filler-container {
        margin: auto;
        text-align: center;
    }
}

@media (max-width:550px){
    #communication-lines{
        height: 100vh;
    }
    #communication-lines #filler h3 {
        font-size: 3em;
    }
    .card {
        flex-direction: column;
    }
    .card > * {
        margin: 25px auto;
    }
}
