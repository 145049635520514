nav-drawers {
    z-index: 89;
    display: block;
    background-color: #46454e;
    width: 100vw;
    height: calc(100vh - var(--nav-height));
    position: fixed;
    top: calc(-100vh + var(--nav-height) * 2);
    transition: top linear 0.2s;
}

nav-drawers.open {
    top: calc(var(--nav-height));
}

#nav-drawer {
    height: 50px;
    display: flex;
    border-bottom: 1px solid white;
    color: white;
}

#nav-drawer:hover {
    background-color: #e78838;
    transition: background-color .2s linear;
}

#nav-drawer.drawer-selected {
    background-color: white;
    color: #46454e;
}

#nav-drawer-inner {
    font-weight: bold;
    margin: auto 10px;
}
