




gallery-viewer-container {
    display: block;
    width: 100vw;
    height: calc(100vh - var(--nav-height));
    top: var(--nav-height);
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 1;
    position: absolute;
    background-color: rgba(0,0,0, 80%);
}

gallery-viewer {
    overflow-y: auto;
    overflow-x: hidden;
    background-color: white;
    display: block;
    height: inherit;
    width: inherit;
    max-width: 1500px;
    height: 100%;
    margin: auto;
    position: relative;
}

gallery-viewer #image-viewer {
    background-color: black;
    height: 50%;
    position: relative;
    color: white;
    display: flex;
}

gallery-viewer #image-viewer #viewer-items {
    height: 100%;
    width:  100%;
    display: flex;
}

gallery-viewer #image-viewer #viewer-items .viewer-item {
    min-height: 100%;
    min-width: 100%;
    display: flex;
    position: relative;
}



gallery-viewer #image-viewer-buttons {
    position: absolute;
    top: 0px;
    bottom: 0px;
    right: 0px;
    left: 0px;
    display: flex;
    justify-content: space-between;
}

gallery-viewer #image-viewer-buttons .direction-button {
    /* background-color: blue; */
    margin: auto 10px;
    height: 50px;
    width: 50px;
    position: relative;
    z-index: 1;
}

gallery-viewer #image-viewer-buttons .direction-button > * {
    color: rgba(255,255,255, 0.5);
    mix-blend-mode: difference;
    height: inherit;
    width: inherit;
}

gallery-viewer #image-viewer-buttons .direction-button > *:hover {
    color: rgba(255,255,255, 0.9);
    transition: 0.25s;
}


gallery-viewer #gallery-item-close {
    position: absolute;
    color: white;
    z-index: 1;
    width: 100%;
    max-width: inherit;
    display: flex;
    justify-content: flex-end;
    margin: auto;
    right: 0px;
    left: 0px;
    padding: 20px;
    border: 0px;
    font-weight: bold;
}

gallery-viewer #gallery-item-action-bar {
    width: 100%;
    margin: 10px 0px;
    display: flex;
    justify-content: flex-end;
}

gallery-viewer #gallery-item-action-bar > * {
    margin: 5px;
}

gallery-viewer #action-items {
    height: 50px;
    width: 100%;
    border-bottom: 1px solid grey;
    display: flex;
    justify-content: flex-end;
}


gallery-viewer #action-items > button {
    padding: 10px;
    color: white;
    text-shadow: 0px 0px 2px black;
    position: relative;

}

gallery-viewer #action-items > button > * {
    /* border: 1px solid black; */
    color: black;
}

gallery-viewer #image-manager label {
    display: flex;
}
gallery-viewer #image-manager label div {
    margin: auto;
}


gallery-viewer #image-manager {
    display: flex;
    flex-direction: row;
    width: 100%;
    overflow: hidden;
    overflow: hidden;
    overflow-x: auto;
    border-bottom: 1px solid grey;
    
    #media-item {
        height: 150px;
        width: 150px;
        min-width: 150px;
        min-height: 150px;
        overflow: hidden;
        position: relative;
        padding: 0;
        margin: 10px 5px;
        z-index: 0;
        box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.40);
        border-radius: 5px;
    }
    
    .media-item-control {
        width: 100%;
        height: 100%;
        display: flex;
        position: relative;

        div {
            position: relative;
            top: 0px;
            width: 100%;
            display: flex;
            flex-direction: row-reverse;

            --var-height: 30px;
            height: var(--var-height);
            button {
                height: var(--var-height);
                width: var(--var-height);

                border-radius: 100%;
                height: 100%;
                display: flex;
                svg {
                    filter: drop-shadow( 0px 1px 5px rgba(0, 0, 0, .7));
                    color: white;
                    margin: auto;
                    height: 100%;
                    display: flex;
                    path {                    
                        margin: auto;
                    }
                }
            }
        }
    }    
    img {
        object-fit: cover;
        object-position: center;
        min-width: 100%;
        min-height: 100%;
        width: 100%;
        position: absolute;
        top:0;
        z-index: -1
    }
}

gallery-viewer #image-viewer-body {
    padding: 25px;
    padding-bottom: 75px;
    min-height: 400px;
    display: flex;
    flex-direction: column;
}

gallery-viewer #image-viewer-body hr {
    margin: 10px 0px;
}

gallery-viewer input#image-viewer-title {
    padding: 10px;
    border-radius: 5px;
    border: 1px solid grey;
    margin: 10px 0px;
    width: calc(100% - 20px);
    max-width: 500px;
    font-size: x-large;
    font-weight: bolder;
}
