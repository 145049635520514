
.media-renderer {
    display: flex;

    background-color: black;
    width: 100%;
    height: 100%;

    & > div {
        max-height: 100%;
        max-width: 100%;
        flex-grow:  1;
        display: flex;
    }

    .media-display {
        margin: auto;
        max-height: inherit;
        margin: auto;
    }

    image.media-display {
        height: 100%;
    }
    video.media-display {
        height: 100%;
        max-width: 100%;
    }

    .render-message {
        margin: auto;
        display: flex;
        div: {
            margin: auto;
        }
    }
}
