

contact-form {
    border-radius: 5px;
    margin: auto ;
    color:#46454e;
    width: 100%;
    display: flex;
    min-height: inherit;
    flex-direction: column;

    font-family: inherit !important;
    font-size: 0.95em;

    contact-form-top {
        display: flex;
        padding: 20px;
        h1 {
            margin: auto;
        }
    }

    contact-form-container {
        min-height: inherit;
        padding: 20px;
        position: relative;
        & > * {
            display: block;
            width: 100%;
            height: 100%;
            top: 0px;
            right: 0px;
        }
    }
}

contact-form-main {
    .contact-error {
        border: 2px solid red;
    }
    .contact-form-errors {
        color: red;
    }
    form {
        display: flex;
        flex-direction: column;
        & > * {
            border: 1px solid grey;
            border-radius: 5px;
            padding: 5px ;
            border: 1px solid #46454e;
            font-size: inherit;
            font-family: inherit;
            margin: 5px 0px;
        }
        & input {
            height: 25px;
        }
        & textarea {
            height: 150px;
            resize: none;
        }

        & button {
            background-color: #46454e;
            color: white;
            font-weight: bold;
            font-size: 1em;
            margin-top: 5px;
            padding: 10px;
            border-radius: 5px;
            border: 0px;

            &:hover {
                background-color:  white;
                margin-top: 5px;
                padding: 10px;
                color: #46454e !important;
            }
        }
    }
}


contact-form-cover {
    background-color: white;
    z-index: 1;
    position: absolute;
    top: 0;

    contact-form-cover-body {
        display: flex;
        height: 100%;
        width: 100%;
        & > * {
            color: green;
            margin: auto;
        }
    }
}
