form-cover {
    background-color: white;
    z-index: 1;
    position: absolute;
    top: 0;
    display:block;
    width: 100%;
    height: 100%;
    display: flex;
    /* border: 1px solid grey; */
    border-radius: 5px;
    box-shadow: 0px 0px 2px black;
}

form-cover svg {
    color: green;
    margin: auto;
}
