@import url('https://fonts.googleapis.com/css2?family=Big+Shoulders+Stencil+Text&display=swap');

nav {
    font-family: 'Big Shoulders Stencil Text', cursive;
    user-select: none;
}

nav {
    z-index: 90;
    display: flex;
    color: #ffffff;
    position: fixed;
    background-color: #46454e;
    width: var(--nav-width);
    height: var(--nav-height);
    align-content: stretch;
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.54);
    /* justify-content: space-between; */
}

nav-placeholder {
    display: block;
    width: var(--nav-width);
    height: var(--nav-height);
}

nav #nav-left {
    width: 33.33%;
    display: flex;
    height: 100%;
}

nav #nav-center {
    width: 33.33%;
    display: flex;
    height: 100%;
}

nav #nav-right {
    width: 33.33%;
    height: 100%;
    display: flex;
    justify-content: flex-end;
}


nav #company-logo-container {
    display: flex;
    margin: auto;
}

nav #company-logo-container #company-logo-text {
    font-size: 250%;
    margin: auto;
    font-weight: bolder;
}

nav #nav-buttons {
    margin: auto 0;
    align-items: center;
}

nav #nav-buttons span {
    padding-right: 10px;
    width: 50px;
    cursor: pointer;
    display: flex;
    height: var(--nav-height);
    justify-content: space-evenly;
}

nav #nav-buttons span > * {
    margin: auto 0;
}
