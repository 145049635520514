

#doc-editor {
}


#doc-editor top-bar  {
    display: block;
    width: 100%;
    height: 50px;
}
#doc-editor top-bar button {
    padding: 5px;
    border: 0.2px solid grey;
    float: right;
}
