gallery-page {
    display:block;
    min-height: inherit;
}


gallery-page #gallery-banner {
    width: 100%;
    height: 250px;
    position: relative;
    font-weight: bold;
    font-family: 'Big Shoulders Stencil Text', cursive;
    text-align: center;
    vertical-align: middle;
    font-size: 3em;
    text-shadow: 0px 0px 2px black;
    background-color: rgba(0,0,0,0.50)
}

gallery-page #gallery-banner-image {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    z-index: -1;
}

gallery-page #gallery-action-items {
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 25px 0px;
}



gallery-page #gallery-body {
    background-color: white;
    /* min-height: 100%; */
    /* min-height: inherit; */
    margin: auto;
    position: relative;
    z-index: 1;
    display: flex;
    flex-wrap: wrap;
    /* justify-content: space-evenly; */
    justify-content: center;
}

gallery-page #gallery-item-body {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}


gallery-page #gallery-item-body > * {
    width: 100%;
    margin: 10px;
}
