* {
    margin: 0;
}


a {
    font-style: inherit;
    text-decoration: none;
    color: inherit;
    outline: 0;

}

a:hover {
    /* text-decoration: underline; */
}

button {
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit
}


:root {
    --nav-height: 60px;
    --nav-width: 100vw;
    --footer-height: 100px;
    --footer-width: 100vw;
    --bg-plus-pattern: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAAGUlEQVQYlWNgIBX8////P1GKYIA6Jo44AAC4NBPt5CzLRAAAAABJRU5ErkJggg==) repeat;
    --bg-dotted-pattern: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAICAYAAADED76LAAAAGUlEQVQYlWNgYGDYDMU4wWYGBka8CoY+AACleALO+P6usQAAAABJRU5ErkJggg==) repeat;
    --bg-grid-pattern: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAcAAAAHCAYAAADEUlfTAAAAGklEQVQImWP4//+/MS7M8P//f2MGXGCwSQIA5PYpccsYSjEAAAAASUVORK5CYII=) repeat;
    --global-shadow: 0px 0px 10px 0px rgba(0,0,0,0.6);
}


button {
    padding: 5px;
    /* border: 1px solid grey; */
    color: grey;
}

#root {
    max-width: 100vw;
    overflow: hidden;
}

html {
    overflow: scroll;
    overflow-x: hidden;
    scrollbar-width: none;  /* Firefox */
}
::-webkit-scrollbar {
    width: 0px;  /* Remove scrollbar space */
    background: transparent;  /* Optional: just make scrollbar invisible */
}
/* Optional: show position indicator in red */
::-webkit-scrollbar-thumb {
    background: #FF0000;
}

body {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

}


.body-no-scroll {
    position: fixed;
    overflow: hidden;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

.DraftEditor-root{
    min-height: 500px;
}
