loading-screen {
    display: flex;
    flex-direction: column;
    width: 100vw;
    max-width: 100%;
    height: 100vh;
    max-height: 100%;
    background-color: #46454e;
}

loading-screen * {
    user-select: none;
}

loading-screen loading-screen-center {
    display: block;
    width: 60vh;
    max-width: calc(100% - 20px);
    margin: auto;
}

loading-screen loading-logo {
    display: flex;
}

loading-bar-container {
    padding-top: 20px;
    display: flex;
    margin: auto;
}

loading-screen loading-logo img  {
    width: 100%;
    box-shadow: none !important;
    margin: auto;
}
