


gallery-panel {
    display: flex;
    flex-direction: column;
    width: 400px;
    height: 400px;
    overflow: hidden;
    position: relative;
    margin: 25px;
    box-shadow: var(--global-shadow);
    border-radius: 1ch;
    transition: 0.2s;
}
gallery-panel:hover{
    /* box-shadow: var(--global-shadow); */
    box-shadow: none;
    transform: scale(0.995);

}

gallery-panel a {
    width: 100%;
    height: 100%;
    color: white;
}


gallery-panel img {
    object-fit: cover;
    object-position: center;
    min-width: 100%;
    min-height: 100%;
    width: 100%;
    z-index: -1;
    position: absolute;
}

#gallery-panel-title-container {
    background-color: #46454e;
    width: 100%;
    position: relative;
    display: flex;
}

#gallery-panel-title-container div {
    margin: 10px;
    color: white;
    font-weight: bold;
}

@media (max-width:900px){
    gallery-panel {
        min-width: calc(100vw - 25px);
        min-height: calc(100vw - 25px);
    }
}
