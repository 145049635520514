@import url('https://fonts.googleapis.com/css2?family=Big+Shoulders+Stencil+Text&display=swap');

:root {
    --impression-height: 50vh;
}


landing-page {
    display: block;
}

.impression-overlay {
    background: var(--bg-grid-pattern) !important;
    z-index: 1;
    /* position: absolute; */
    width: 100%;
    /*
        Fix this
    */
    max-height: 50vh;
    height: 50vh;
    background-color: rgba(0, 0,0, .7);
    color: white;
    overflow: hidden;
    /* display: flex; */
}
.flashing-text-container {
    height: 100%;
    width: 100%;
    display: flex;
}

.flashing-text {
    font-family: 'Big Shoulders Stencil Text', cursive;
    user-select: none;
    text-align: center;
    font-weight: bold;
    font-size: calc(5vw + 5vh);
    margin: auto;
    text-shadow: 2px 2px #46454e;

}
#app-body {
    display: flex;
    flex-direction: column;
}

#landing-body {

    height: calc(100vh - var(--impression-height) - var(--nav-height) + 20px);
}

#landing-contact-button-container {
    background-color: white;
    width: 100%;
    display: flex;
    height: 100%;
    background: var(--bg-dotted-pattern);
}


#landing-contact-button {
    color: white;
    border: 2px solid #46454e;
    background-color: #46454e;
    font-family: 'Big Shoulders Stencil Text', cursive;
    border-radius: 0.25ch;
    padding: 20px;
    margin: 20px;
    margin: auto;
    font-size: calc(2vw + 2vh);
    transition: 0.2s;
    font-weight: bold;
    box-shadow: var(--global-shadow);
}

#landing-contact-button:hover {
    color: #46454e;
    background-color: white;
    border: 2px solid #46454e;
}
