loading-bar {
    display: flex;
    width: var(--loading-bar-width);
    height: var(--loading-bar-height);
}

loading-bar outer-bar {
    display: flex;
    width: 100%;
    border: .125rem solid #fff;
    overflow: hidden;
    border-radius: 5px;
}

loading-bar outer-bar inner-bar {
    display: block;
    background-color: #292828;
    width: var(--loading-bar-percentage);
}
